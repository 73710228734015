import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faYoutube, faLinkedinIn, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFile, faLink } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import Layout from "./layout"
import SEO from "./seo"

const ProfessionalsPage = ({ data: { strapiIcdaCareers } }) => {
  return (
    <Layout>
      <SEO title="Nuestros Profesionales - ICDA" />
      <section>
        <div>
          <div className="w-100 d-flex bg-light inst-img">
            <div className="w-100 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center text-white p-5 background">
              <h2 className="mb-4 fw-bold text-uppercase title">
                {strapiIcdaCareers.name}
              </h2>
            </div>
          </div>

          <div className="container">
            <div className="my-4">
              <h5 className="mb-4">Nuestro Faculty</h5>
              <div className="row">
                {strapiIcdaCareers.professionals
                  ? strapiIcdaCareers.professionals.length > 0
                    ? strapiIcdaCareers.professionals.map((prof, index) => (
                        <div className="col-12 col-sm-6 col-lg-3 mb-4">
                          <div className="shadow-sm bg-white professional-card rounded px-4 pt-4 pb-2 h-100 d-flex flex-column align-items-center">
                            <div
                              className="img-prof mb-3 bg-secondary"
                              style={{
                                backgroundImage: `${
                                  prof.profilePicture
                                    ? `url(${prof.profilePicture.url})`
                                    : null
                                }`,
                              }}
                            ></div>
                            
                            <h6>{prof.name}</h6>
                            
                            <h6 className="mb-3">
                              {/* <small>{professionals.rol}</small> */}
                            </h6>

                            <div className="pt-2 border-top mb-2 flex-grow-1 d-flex flex-column">
                              <div className="pr-1 info-cont position-relative overflow-auto">
                                <ReactMarkdown className="format-markdown">
                                  {prof.description}
                                </ReactMarkdown>
                              </div>
                            </div>
                            
                            {prof?.profesionalLinks.length > 0 ? (
                              <div className="social w-100 d-flex justify-content-center pt-2 border-top mb-2">
                                {prof.profesionalLinks.map(
                                  (item) => {
                                    switch (item.type) {
                                      case 'facebook':
                                        return (
                                          <a
                                            href={item?.url}
                                            target="_blank"
                                            className="btn btn-social m-1 rounded-circle btn-social-icon btn-facebook"
                                            rel="noreferrer"
                                          >
                                            {' '}
                                            <FontAwesomeIcon icon={faFacebookF} />
                                          </a>
                                        )
                                      case 'youtube':
                                        return (
                                          <a
                                            href={item?.url}
                                            target="_blank"
                                            className="btn btn-social m-1 rounded-circle btn-social-icon btn-youtube"
                                            rel="noreferrer"
                                          >
                                            <FontAwesomeIcon icon={faYoutube} />
                                          </a>
                                        )
                                      case 'linkedin':
                                        return (
                                          <a
                                            href={item?.url}
                                            target="_blank"
                                            className="btn btn-social m-1 rounded-circle btn-social-icon btn-linkedin"
                                            rel="noreferrer"
                                          >
                                            <FontAwesomeIcon icon={faLinkedinIn} />
                                          </a>
                                        )
                                      case 'twitter':
                                        return (
                                          <a
                                            href={item?.url}
                                            target="_blank"
                                            className="btn btn-social m-1 rounded-circle btn-social-icon btn-twitter"
                                            rel="noreferrer"
                                          >
                                            <FontAwesomeIcon icon={faTwitter} />
                                          </a>
                                        )
                                      case 'instagram':
                                        return (
                                          <a
                                            href={item?.url}
                                            target="_blank"
                                            className="btn btn-social m-1 rounded-circle btn-social-icon btn-instagram"
                                            rel="noreferrer"
                                          >
                                            <FontAwesomeIcon icon={faInstagram} />
                                          </a>
                                        )
                                      case 'curriculum':
                                        return (
                                          <a
                                            href={item?.url}
                                            target="_blank"
                                            className="btn btn-social m-1 rounded-circle btn-social-icon btn-curriculum"
                                            rel="noreferrer"
                                          >
                                            <FontAwesomeIcon icon={faFile} />
                                          </a>
                                        )
                                      case 'correo':
                                        return (
                                          <a
                                            href={item?.url}
                                            target="_blank"
                                            className="btn btn-social m-1 rounded-circle btn-social-icon btn-correo"
                                            rel="noreferrer"
                                          >
                                            <FontAwesomeIcon icon={faEnvelope} />
                                          </a>
                                        )
                                      case 'otro':
                                        return (  
                                          <a
                                            href={item?.url}
                                            target="_blank"
                                            className="btn btn-social m-1 rounded-circle btn-social-icon btn-other"
                                            rel="noreferrer"
                                          >
                                            <FontAwesomeIcon icon={faLink} />
                                          </a>
                                        )
                                    }
                                  }
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ))
                    : null
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProfessionalsPage

export const professionalsQuery = graphql`
  query($id: String) {
    strapiIcdaCareers(id: { eq: $id }) {
      id
      name
      professionals {
        name
        description
        profilePicture {
          url
        }
        profesionalLinks{
          id
          type
          url
        }
      }
    }
  }
`
